import { FC, useEffect, useState } from 'react';
import { DashboardEditPanel } from 'src/components/Dashboard/DashboardEditPanel';
import { DashboardEditModal } from 'src/components/Dashboard/DashboardEditModal';
import { DashboardTableHead } from 'src/components/Dashboard/DashboardTableHead';
import { DashboardTableItem } from 'src/components/Dashboard/DashboardTableItem';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setIsAllChecked, setIsExcelUploadVisible } from 'src/store/common';
import { checkUploading, isCustomFilterEmpty, isFiltersEmpty } from 'src/utils';
import { useAuth } from 'src/hooks/useAuth';
import { getFilters } from 'src/utils/helpers';
import { Loader } from 'src/components/ui/Loader';
import { setCheckedPolygons } from 'src/store/polygons';
import { ImportExcelStatus } from 'src/components/Excel/ImportExcelStatus';
import { polygonTableFieldsForMap } from 'src/config/data';
import { DashboardTableSort, FetchReestrPolygonsParamsParams, PolygonType } from 'src/config/types';
import PolygonsService from 'src/services/PolygonsService';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import loadingGif from 'src/assets/gifs/loading.gif';

export const DashboardPage: FC = () => {
  const { polygonsFilter, dashboardPolygonsFilter } = useAppSelector(state => state.filters);
  const { isExcelUpload, isExcelUploadVisible, isAllChecked } = useAppSelector(
    state => state.common,
  );
  const { checkedPolygons } = useAppSelector(state => state.polygons);

  const [polygons, setPolygons] = useState<PolygonType[]>([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<DashboardTableSort>();
  const [isLoadingPolygons, setIsLoadingPolygons] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [trigger, setTrigger] = useState(false);
  const [count, setCount] = useState(0);

  const { width } = useWindowDimensions();
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const handleCloseLoading = () => dispatch(setIsExcelUploadVisible(false));

  const getPolygons = async (params: FetchReestrPolygonsParamsParams, add?: boolean) => {
    try {
      setIsLoadingPolygons(!add);
      const response = await PolygonsService.getAllReestrPolygons(params);

      if (response.detail) return;

      setCount(response?.count);
      if (add) {
        setPolygons(prev => [...prev, ...response.results.features]);
      } else {
        setPolygons(response.results.features);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoadingPolygons(false);
    }
  };

  useEffect(() => {
    checkUploading(dispatch).then();
  }, []);

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const loadMore = async () => {
    const newPage = page + 1;
    await getPolygons(
      {
        fields: polygonTableFieldsForMap,
        page: newPage,
        sort,
        filter: getFilters(polygonsFilter, dashboardPolygonsFilter),
      },
      true,
    );
    setPage(newPage);
  };

  const handleSetChecked = (polygon: PolygonType, isChecked: boolean) => {
    if (!(user?.isLeader || user?.isManager)) return;

    if (isChecked) {
      dispatch(
        setCheckedPolygons(checkedPolygons.filter(prevPolygon => prevPolygon.id !== polygon.id)),
      );
      dispatch(setIsAllChecked(false));
    } else {
      if (checkedPolygons.length + 1 === count) {
        dispatch(setIsAllChecked(true));
      }
      dispatch(setCheckedPolygons([...checkedPolygons, polygon]));
    }
  };

  const handleSetSort = async (sort: DashboardTableSort) => {
    await getPolygons({ fields: polygonTableFieldsForMap, page: 1, sort });
    setPage(1);
    setSort(sort);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    setPage(1);

    if (
      !isFiltersEmpty(polygonsFilter) ||
      !isCustomFilterEmpty(dashboardPolygonsFilter) ||
      dashboardPolygonsFilter?.price ||
      dashboardPolygonsFilter?.square ||
      dashboardPolygonsFilter?.pzz ||
      dashboardPolygonsFilter?.master_plan
    ) {
      getPolygons({
        fields: polygonTableFieldsForMap,
        filter: getFilters(polygonsFilter, dashboardPolygonsFilter),
        page: 1,
      }).then();
    } else {
      getPolygons({ fields: polygonTableFieldsForMap, page: 1 }).then();
    }
  }, [polygonsFilter, dashboardPolygonsFilter, user, trigger]);

  return (
    <div className="t-lg:min-w-fit w-full h-full relative overflow-x-auto overflow-y-hidden">
      {isExcelUpload && isExcelUploadVisible ? (
        <div
          className={
            'w-full t-lg:w-fit h-8 bg-yellow-600 min-h-[2rem] flex items-center t-lg:rounded-l-lg ' +
            'px-4 t-lg:px-0 t-lg:h-16 t-lg:fixed t-lg:top-2 t-lg:right-0 t-lg:z-2600 ' +
            'overflow-hidden'
          }
        >
          <div className="h-full square">
            <img src={loadingGif} className="w-full h-full object-contain" alt="" />
          </div>
          <div className="w-0.5 h-full bg-yellow-400 mx-4 t-lg:mx-0" />
          <div className="text-yellow-300 text-xs t-lg:mx-4">Идет загрузка файла...</div>
          <div
            className={
              'w-5 h-5 cursor-pointer ml-auto bg-yellow-500 center rounded-full t-lg:rounded-l-lg ' +
              't-lg:h-full t-lg:rounded-r-none'
            }
            onClick={handleCloseLoading}
          >
            {width >= 1024 ? (
              <ArrowIcon className="w-5 h-5 rotate-90 stroke-path-yellow-600" />
            ) : (
              <CloseIcon className="w-2.5 h-2.5 fill-yellow-600" />
            )}
          </div>
        </div>
      ) : null}

      <DashboardTableHead polygons={polygons} sort={sort} setSort={handleSetSort} />

      <div
        className="t-lg:w-full w-fit flex flex-col gap-0.5 overflow-y-auto overflow-x-hidden pb-20"
        style={{ height: `calc(100% - ${width >= 1024 ? 52 : 32}px)` }}
        id="table-scroll"
      >
        {isLoadingPolygons ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader classNames="w-20 h-20" />
          </div>
        ) : null}

        {!isLoadingPolygons
          ? polygons.map((polygon, i) => (
              <DashboardTableItem
                isChecked={
                  !!checkedPolygons.find(checkedPolygon => checkedPolygon.id === polygon.id)
                }
                setChecked={handleSetChecked}
                polygon={polygon}
                key={`dashboard-table-item-${polygon.id}`}
                withObserver={polygons.length - 3 === i}
                loadMore={loadMore}
              />
            ))
          : null}
      </div>

      {user?.isLeader || user?.isManager ? (
        <DashboardEditPanel
          isAllChecked={isAllChecked}
          count={count}
          onClick={handleOpenEditModal}
        />
      ) : null}

      {(user?.isLeader || user?.isManager) && isEditModalOpen ? (
        <DashboardEditModal onClose={handleCloseEditModal} setTrigger={setTrigger} />
      ) : null}

      <ImportExcelStatus />
    </div>
  );
};
