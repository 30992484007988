import { FC, useMemo, Fragment } from 'react';
import { stringToCoords } from 'src/utils';
import { geoJSONToCoords, getPolygonColors } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { FeatureGroup, Polygon } from 'react-leaflet';
import { useAppSelector } from 'src/hooks/useRedux';
import { PolygonType } from 'src/config/types';
import { LatLngExpression } from 'leaflet';

interface LeafletMapPolygonsProps {
  polygons: PolygonType[];
  bounds?: LatLngExpression;
}

export const LeafletMapPolygons: FC<LeafletMapPolygonsProps> = ({ polygons, bounds }) => {
  const { selectedPolygon } = useAppSelector(state => state.polygons);
  const { isClustersActive } = useAppSelector(state => state.common);
  const categories = useAppSelector(state => state.categories);
  const lassoFilter = useAppSelector(state => state.filters.lassoFilter);

  const { user } = useAuth();

  const checkVisible = (polygon: PolygonType, isClustersActive: boolean, bounds) => {
    if (isClustersActive && polygon?.id) {
      return document.querySelector(
        `.marker-${
          polygon?.properties?.cadaster_number || polygon?.cadaster_number ? 'reestr' : 'draw'
        }-${polygon.id}`,
      );
    }

    if (lassoFilter?.positions?.length) {
      return (lassoFilter?.polygonIds || []).includes(polygon?.id || 0);
    }

    const center = stringToCoords(polygon?.properties?.center || polygon?.center || '');
    return (
      center[0] <= bounds._northEast.lat + 10 &&
      center[1] <= bounds._northEast.lng + 10 &&
      center[0] >= bounds._southWest.lat - 10 &&
      center[1] >= bounds._southWest.lng - 10
    );
  };

  const renderPolygon = (polygon: PolygonType, i: number) => {
    if (!bounds || !polygon?.geometry || !user) {
      return <Fragment key={`polygon-with-copy-${i}`} />;
    }

    if (checkVisible(polygon, isClustersActive, bounds)) {
      const positions = geoJSONToCoords(polygon.geometry);
      const isSelected = polygon?.id === selectedPolygon?.id;

      const { fill, stroke, hatching } = getPolygonColors(polygon, categories, user);

      return (
        <Fragment key={`polygon-with-copy-${i}`}>
          {positions.map((coords, i) => (
            <Fragment key={`selected-multi-${i}`}>
              <Polygon
                positions={[coords]}
                pathOptions={{
                  fillOpacity: isSelected ? 0.9 : 0.5,
                  fillColor: fill,
                  stroke: false,
                }}
                className={`fill-${polygon.id}`}
              />
              <Polygon
                positions={[coords]}
                pathOptions={{
                  fillOpacity: 1,
                  weight: isSelected ? 6 : 3,
                  color: stroke,
                  fillColor: hatching
                    ? `url(#pattern-${hatching.replace('#', '')})`
                    : 'transparent',
                }}
                className={`stroke-${polygon.id}`}
              />
            </Fragment>
          ))}
        </Fragment>
      );
    } else {
      return <Fragment key={`polygon-with-copy-${i}`} />;
    }
  };

  const renderSelectedPolygon = useMemo(() => {
    if (!selectedPolygon) {
      return <></>;
    }
    return renderPolygon(selectedPolygon, polygons.length);
  }, [selectedPolygon, polygons, bounds]);

  const polygonsList = useMemo(
    () => <>{polygons.map((polygon, i) => renderPolygon(polygon, i))}</>,
    [polygons, isClustersActive, bounds, lassoFilter],
  );

  return (
    <FeatureGroup>
      {polygonsList}

      {(selectedPolygon && !selectedPolygon?.id) ||
      (selectedPolygon && !selectedPolygon?.properties?.cadaster_number)
        ? renderSelectedPolygon
        : null}
    </FeatureGroup>
  );
};
